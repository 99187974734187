"use client";
import { syncLogger } from "@/debug/sync";
import { database } from "@/watermelondb";
// @ts-ignore
import { parse } from "cookie";

export function clearStorage() {
  syncLogger("Start cleanup process");
  if (typeof window === "undefined") return;
  syncLogger("Cleanup localStorage");
  localStorage.clear();
  syncLogger("Cleanup cookie");
  const cookies = parse(document.cookie);
  for (const cookieName in cookies) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  syncLogger("Cleanup indexedDB");
  indexedDB.deleteDatabase("WatermelonIDBChecker");
  indexedDB.deleteDatabase("loki.db");
  syncLogger("End cleanup process");
}

export async function clearOnlyWatermelonDB() {
  await database.write(async () => {
    await database.unsafeResetDatabase();
  });
}
