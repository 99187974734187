import { useEffect } from "react";
import { loginOpenState } from "@/jotai";
import { useAtom, useSetAtom } from "jotai";
import s from "./style.module.css";
import { createClient } from "@/supabase/utils/client";
export default function Layout() {
  return (
    <div className="flex justify-center ">
      <div className="max-w-[700px] w-full px-10">
        <Top></Top>
        <div className={`flex flex-col gap-[150px] mt-[50px]`}>
          <Slogans></Slogans>
          <Auth></Auth>
          <Notice label="알림" body="현재 OTU는 베타 테스트 중입니다."></Notice>
          <OrgSlogan></OrgSlogan>
        </div>
        {/* <BgGrid></BgGrid> */}
        <div className={s.note_pattern}></div>
      </div>
    </div>
  );
}

function Top() {
  const setLoginOpen = useSetAtom(loginOpenState);
  return (
    <div className={`relative invert dark:invert-0 ${s.dark}`}>
      <div className="flex justify-center items-baseline gap-2 p-[30px]">
        <svg
          width="55"
          height="37"
          viewBox="0 0 55 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.42285 36.5C5.72285 36.5 3.65619 35.7333 2.22285 34.2C0.789518 32.6667 0.0728516 30.5 0.0728516 27.7V9.3C0.0728516 6.5 0.789518 4.33333 2.22285 2.8C3.65619 1.26666 5.72285 0.499998 8.42285 0.499998C11.1229 0.499998 13.1895 1.26666 14.6229 2.8C16.0562 4.33333 16.7729 6.5 16.7729 9.3V27.7C16.7729 30.5 16.0562 32.6667 14.6229 34.2C13.1895 35.7333 11.1229 36.5 8.42285 36.5ZM8.42285 31.5C10.3229 31.5 11.2729 30.35 11.2729 28.05V8.95C11.2729 6.65 10.3229 5.5 8.42285 5.5C6.52285 5.5 5.57285 6.65 5.57285 8.95V28.05C5.57285 30.35 6.52285 31.5 8.42285 31.5ZM24.5971 6H18.8471V0.999998H35.8471V6H30.0971V36H24.5971V6ZM46.51 36.5C43.8433 36.5 41.81 35.75 40.41 34.25C39.01 32.7167 38.31 30.5333 38.31 27.7V0.999998H43.81V28.1C43.81 29.3 44.0433 30.1667 44.51 30.7C45.01 31.2333 45.71 31.5 46.61 31.5C47.51 31.5 48.1933 31.2333 48.66 30.7C49.16 30.1667 49.41 29.3 49.41 28.1V0.999998H54.71V27.7C54.71 30.5333 54.01 32.7167 52.61 34.25C51.21 35.75 49.1766 36.5 46.51 36.5Z"
            fill="white"
          />
        </svg>
      </div>
      <button
        title="로그인"
        className="absolute right-[24px] top-10 outline-none"
        onClick={() => {
          setLoginOpen({ open: true, mode: "login" });
        }}
      >
        <svg
          width="30"
          height="20"
          viewBox="0 0 40 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.43852 12V1.005H1.83352V10.83H6.63352V12H0.43852ZM11.3937 12.21C9.38368 12.21 7.61368 10.635 7.61368 7.95C7.61368 5.22 9.38368 3.66 11.3937 3.66C13.3887 3.66 15.1587 5.22 15.1587 7.95C15.1587 10.635 13.3887 12.21 11.3937 12.21ZM11.3937 11.07C12.8037 11.07 13.7487 9.81 13.7487 7.95C13.7487 6.06 12.8037 4.8 11.3937 4.8C9.96868 4.8 9.02368 6.06 9.02368 7.95C9.02368 9.81 9.96868 11.07 11.3937 11.07ZM20.0704 15.75C18.0754 15.75 16.6954 14.97 16.6954 13.575C16.6954 12.885 17.1454 12.21 17.8954 11.73V11.67C17.4754 11.4 17.1604 10.965 17.1604 10.32C17.1604 9.645 17.6404 9.12 18.0754 8.82V8.76C17.5354 8.325 16.9954 7.545 16.9954 6.57C16.9954 4.77 18.4054 3.66 20.0554 3.66C20.5204 3.66 20.9254 3.75 21.2104 3.855H24.0454V4.92H22.3804C22.7704 5.295 23.0404 5.895 23.0404 6.6C23.0404 8.34 21.7204 9.42 20.0554 9.42C19.6654 9.42 19.2154 9.33 18.8404 9.15C18.5554 9.39 18.3154 9.675 18.3154 10.11C18.3154 10.635 18.6604 11.01 19.7554 11.01H21.3454C23.2354 11.01 24.1804 11.595 24.1804 12.945C24.1804 14.46 22.5754 15.75 20.0704 15.75ZM20.0554 8.505C21.0004 8.505 21.7954 7.74 21.7954 6.57C21.7954 5.37 21.0154 4.65 20.0554 4.65C19.0954 4.65 18.3154 5.355 18.3154 6.57C18.3154 7.74 19.1104 8.505 20.0554 8.505ZM20.2504 14.82C21.8254 14.82 22.8454 13.995 22.8454 13.155C22.8454 12.39 22.2604 12.135 21.1954 12.135H19.7854C19.4704 12.135 19.0954 12.105 18.7204 12C18.1354 12.42 17.8954 12.915 17.8954 13.38C17.8954 14.265 18.7804 14.82 20.2504 14.82ZM29.1466 12V3.855H30.5116V12H29.1466ZM29.8366 2.175C29.2966 2.175 28.9216 1.83 28.9216 1.26C28.9216 0.734999 29.2966 0.389999 29.8366 0.389999C30.3616 0.389999 30.7516 0.734999 30.7516 1.26C30.7516 1.83 30.3616 2.175 29.8366 2.175ZM33.2775 12V3.855H34.3875L34.5225 5.04H34.5675C35.3325 4.275 36.1725 3.66 37.2975 3.66C39.0075 3.66 39.7875 4.77 39.7875 6.84V12H38.4075V7.02C38.4075 5.49 37.9425 4.845 36.8625 4.845C36.0375 4.845 35.4525 5.28 34.6425 6.09V12H33.2775Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  );
}
function Slogans() {
  return (
    <div className="relative flex flex-col gap-[95px]">
      <Slogan
        title="AI 노트"
        body="AI 시대에 어울리는 기록은 무엇일까?"
        direction="left"
      ></Slogan>
      <Slogan
        title="나를 위한 AI"
        body="내 기록을 바탕으로 AI가 답변합니다"
        direction="right"
      ></Slogan>
      <Slogan
        title="손쉬운 기록"
        body="글과 사진을 분석하여 제목은 AI가 입력합니다."
        direction="left"
      ></Slogan>
    </div>
  );
}
type SloganProps = {
  title: string;
  body: string;
  direction?: "left" | "right";
};
function Slogan({ title, body, direction }: SloganProps) {
  return (
    <div className={`text-${direction} text-black dark:text-white`}>
      <h2 className="mb-[13px] text-[19px] font-[600]">{title}</h2>
      <div className="text-[13px]">{body}</div>
    </div>
  );
}

function Auth() {
  const setLoginOpen = useSetAtom(loginOpenState);
  return (
    <div className="flex flex-col items-center gap-[22px] text-black dark:text-white">
      <svg
        className="invert dark:invert-0"
        width="31"
        height="16"
        viewBox="0 0 31 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.2899 12.2305H0V15.994H11.2899V12.2305Z" fill="white" />
        <path
          d="M20.6981 6.11621H9.4082V9.8797H20.6981V6.11621Z"
          fill="white"
        />
        <path d="M30.1063 0H18.8164V3.76349H30.1063V0Z" fill="white" />
      </svg>
      <div>AI와 함께 기록을 시작해 보세요.</div>
      <div className="flex justify-center gap-[11px]">
        <AuthButton
          label="가입"
          onClick={() => {
            setLoginOpen({ open: true, mode: "login" });
          }}
        ></AuthButton>
        <AuthButton
          label="로그인"
          onClick={() => {
            setLoginOpen({ open: true, mode: "login" });
          }}
        ></AuthButton>
      </div>
    </div>
  );
}

// @ts-ignore
function AuthButton({ label, onClick }) {
  return (
    <button
      onClick={onClick}
      className="border-[2px] border-[black] dark:border-[white] py-[6.75px] px-[25px] text-[13px]"
    >
      {label}
    </button>
  );
}
// @ts-ignore
function Notice({ label, body }) {
  return (
    <div className="text-center text-white bg-black dark:bg-white dark:text-black py-[26px]">
      <h2 className="text-[18px]">{label}</h2>
      <div className="text-[13px]">{body}</div>
    </div>
  );
}
function OrgSlogan() {
  return (
    <div className="text-center py-[26px] text-black dark:text-white">
      <h2 className="text-[14px]">
        남이 할 수 있는 걸 나도 할 수 있게<br></br>
        내가 할 수 있는 걸 남도 할 수 있게
      </h2>
      <div className="text-[10px] mt-[22px]">오픈튜토리얼스</div>
    </div>
  );
}
function BgGrid() {
  const lineCount = 20; // 선의 개수
  const gap = 25; // 선 간의 간격

  return (
    <svg
      className="absolute left-0 top-0 w-full"
      viewBox="0 0 1000 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {Array.from({ length: lineCount }).map((_, index) => (
        <line
          key={index}
          y1={index * gap}
          x2="100%"
          y2={index * gap}
          stroke="white"
          stroke-opacity="0.3"
          stroke-width="0.5"
        />
      ))}
    </svg>
  );
}
