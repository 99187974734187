'use client';
import LogoutedHome from "@/components/home/logouted/layout";
import { useEffect } from "react";
// @ts-ignore
import { clearStorage } from "@/functions/clearStorage";
export default function Page() {
  useEffect(()=>{
    setTimeout(()=>{
      clearStorage();
    },3000)
  },[])
  return <><LogoutedHome /></>;
}
